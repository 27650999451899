import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete, FeTextField } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const surgeryModalityOptions = [
  "Lumpectomy with ALND",
  "Lumpectomy with SnLNBx",
  "Mastectomy with ALND",
  "Mastectomy with SnLNBx",
  "Unplanned Limpectomy",
];
const MolecularForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    molecular_pathologies: [
      {
        detail: "",
        date: null,
      },
    ],
  });

  const [modList, setModList] = useState(surgeryModalityOptions);

  const addDiagnosisField = () => {
    setObj({
      ...obj,
      molecular_pathologies: [
        ...obj.molecular_pathologies,
        {
          date: null,
          detail: "",
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.molecular_pathologies];
    rows.splice(index, 1);
    setObj({ ...obj, molecular_pathologies: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.molecular_pathologies];
    list[index][name] = value;
    setObj({ ...obj, molecular_pathologies: list });
  };
  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.molecular_pathologies.length === 0) {
        setObj({
          molecular_pathologies: [
            {
              detail: "",
              date: null,
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);
  // useEffect(() => {
  //   if (editedObj) {
  //     setObj(Object.assign({}, obj, editedObj));
  //   }
  // }, [editedObj]);
  // useEffect(() => {
  //   if (updatedObj) {
  //     const u = updateObjectValue(obj, updatedObj);
  //     setTimeout(() => {
  //       setObj(u);
  //     }, 100);
  //   }
  // }, [updatedObj]);
  return (
    <>
      {" "}
      <Grid item xs={8} sm={8}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid mb={1} item sm={4}>
              Molecular Pathology{" "}
            </Grid>
          </Grid>
          {obj.molecular_pathologies.map((data, index) => {
            const { date, detail } = data;

            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6} md={4} mb={1}>
                  <FeTextField
                    value={detail}
                    onChange={(e) => {
                      handleChangeDiagnosis(index, "detail", e.target.value);
                    }}
                    label="Detail"
                  />
                </Grid>
                <Grid item xs={6} md={4} mb={1}>
                  <FeDesktopDatePicker
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    name="date"
                    value={date}
                    //  disabled={editedObj}
                    onChange={(val) => {
                      handleChangeDiagnosis(
                        index,
                        "date",
                        dayjs(val).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  {obj.molecular_pathologies.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      //  disabled={editedObj}
                      onClick={() => removeDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="success"
              //  disabled={editedObj}
              onClick={addDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MolecularForm;
