import { Box, Typography, FormControl, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FeTextField } from "../../components/FormElements";
import Logo from "../../images/logo.png";
import LoginBg from "../../images/login__bg.jpg";
import banner from "../../images/banner.jpeg";
import bsbcs from "../../images/BSBCS-logo.png";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASE_URL;

const DoctorLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      const { data } = await axios.post(
        `${baseURL}doctor/auth/login`,
        userInfo
      );
      setError("");

      console.log("DATAAT", data);

      localStorage.setItem("CANCER_USER_INFO", JSON.stringify(data.data));
      navigate("/doctor/patient-observations");
    } catch (error) {
      console.log("ERRRR", error.response);

      if (error.response && error.response.data) {
        setError(error.response.data.error);
      }
    }
  };
  return (
    <Box className="Login__page" height="100vh" display="flex">
      <Box
        height="100%"
        pt={2}
        px={4}
        width="100%"
        maxWidth="400px"
        display="flex"
        flexDirection="column"
      >
        <Box>
          <img src={Logo} width="150" />
        </Box>
        <Box flexGrow={1} py={3} display="flex" alignItems="center">
          <Box component="form" width="100%" noValidate>
            <Box mb={3}>
              <Typography component="h1" variant="h4">
                Doctor Login
              </Typography>
            </Box>
            <Box mb={3}>
              <FeTextField
                label="Email"
                required
                id="email"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
              />
            </Box>
            <Box mb={2}>
              <FeTextField
                label="Password"
                required
                id="password"
                onChange={(e) =>
                  setUserInfo({ ...userInfo, password: e.target.value })
                }
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box mb={3}>
              <Typography
                variant="caption"
                component="p"
                textAlign="left"
                sx={{
                  textDecoration: "none",
                  color: "red",
                }}
              >
                {error}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography
                variant="caption"
                component="p"
                textAlign="right"
                sx={{
                  a: {
                    textDecoration: "none",
                    color: "#144272",
                  },
                }}
              >
                <Link
                  to="/doctor/forget-password"
                  component={Button}
                  variant="contained"
                  fullWidth
                >
                  Forgot Password
                </Link>
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => handleLogin()}
                variant="contained"
                fullWidth
                size="large"
              >
                Login
              </Button>
            </Box>
          </Box>
        </Box>
        <Box textAlign="center" pb={2}>
          <Typography variant="caption">
            Copyright &copy; 2023. All Rights Reserved.{" "}
          </Typography>
        </Box>
      </Box>
      {/* <section class="FlexContainer">
        <div></div>
        <div></div>
      </section> */}
      <Box
        height="100%"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "flex-start",
          height: "100%",
          overflow: "auto",
          flexDirection: "column",
        }}
        // flexGrow={1}
        // sx={{
        //   backgroundImage: `url(${LoginBg})`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   position: "relative",
        //   "&:before": {
        //     content: '""',
        //     display: "block",
        //     width: "100%",
        //     height: "100%",
        //     position: "absolute",
        //     top: 0,
        //     left: 0,
        //     background:
        //       "radial-gradient(100% 225% at 100% 0%, #FF0000 0%, #000000 100%), linear-gradient(236deg, #00C2FF 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 0%, #CDFFEB 36%, #009F9D 36%, #009F9D 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)",
        //     backgroundBlendMode: "overlay, hard-light, normal;",
        //     opacity: 0.8,
        //   },
        // }}
      >
        <img src={bsbcs} className="img-responsive-1" />
        <img src={banner} className="img-responsive" />
      </Box>
    </Box>
  );
};

export default DoctorLogin;
