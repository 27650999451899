import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { FeTextField, FeAutocomplete } from "../../../FormElements";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import adminAPi from "../../../../utils/adminAPi";
import { isEmpty } from "lodash";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import dayjs from "dayjs";
import { updateObjectValue } from "../../../../utils/helper";

const CancerMarker = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    cancer_markers: [
      {
        name: null,
        value: "",
        unit: "",
        date: null,
      },
    ],
  });

  const [groupList, setGroupList] = useState([]);

  const [groupValue, setGroupValue] = useState(null);
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.cancer_markers.length === 0) {
        setObj({
          cancer_markers: [
            {
              name: null,
              value: "",
              unit: "",
              date: null,
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);
  // useEffect(() => {
  //   if (updatedObj) {
  //     const u = updateObjectValue(obj, updatedObj);
  //     setTimeout(() => {
  //       setObj(u);
  //     }, 100);
  //   }
  // }, [updatedObj]);
  // useEffect(() => {
  //   if (editedObj) {
  //     setObj(Object.assign({}, obj, editedObj));
  //   }
  // }, [editedObj]);
  useEffect(() => {
    getGroupList();

    return () => {};
  }, []);

  const getGroupList = async () => {
    const { data } = await adminAPi.get("admin/cancer-marker-records-all");
    setGroupList(data.data);
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  const addDiagnosisField = () => {
    setObj({
      ...obj,
      cancer_markers: [
        ...obj.cancer_markers,
        {
          name: "",
          value: "",
          unit: "",
          date: null,
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.cancer_markers];
    rows.splice(index, 1);
    setObj({ ...obj, cancer_markers: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.cancer_markers];
    list[index][name] = value;
    setObj({ ...obj, cancer_markers: list });
  };

  return (
    <>
      {" "}
      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>
          <Grid mb={1} item sm={4}>
            Cancer Marker
          </Grid>
        </Grid>
        {obj.cancer_markers.map((data, index) => {
          const { name, value, unit, date } = data;

          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={3} md={3} mb={1}>
                <Box mb={2}>
                  <FeAutocomplete
                    // getOptionLabel={(option) =>
                    //   editedObj ? option.detail : option.name
                    // }
                    //  disabled={editedObj}
                    value={name}
                    onChange={(e, v) => {
                      if (!v) {
                        handleChangeDiagnosis(index, "name", v);
                        handleChangeDiagnosis(index, "unit", "");
                        return;
                      }
                      console.log("VVVVV", v);
                      // const objtoPost = {
                      //   name: v.name,
                      //   value: v.unit,
                      // };
                      // handleChangeInput(objtoPost);
                      handleChangeDiagnosis(index, "name", v);
                      handleChangeDiagnosis(
                        index,
                        "unit",
                        groupList.filter((o) => o.name === v)[0].unit
                      );
                      // setGroupValue(v);
                    }}
                    label={"Name"}
                    options={groupList.map((o) => o.name)}
                    id="Histopathology"
                  />
                </Box>
              </Grid>
              <Grid item xs={2} md={2} mb={1}>
                <FeTextField value={unit} label="Unit" />
              </Grid>
              <Grid item xs={3} md={3}>
                <FeTextField
                  value={value}
                  onChange={(e) => {
                    handleChangeDiagnosis(index, "value", e.target.value);
                  }}
                  label="Value"
                />
              </Grid>

              <Grid item xs={3} md={3}>
                <FeDesktopDatePicker
                  label=" Date"
                  inputFormat="DD/MM/YYYY"
                  name="date"
                  value={date}
                  //  disabled={editedObj}
                  onChange={(val) => {
                    handleChangeDiagnosis(
                      index,
                      "date",
                      dayjs(val).format("YYYY-MM-DD")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                {obj.cancer_markers.length !== 1 && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    color="error"
                    //  disabled={editedObj}
                    onClick={() => removeDiagnosisFields(index)}
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="success"
            //  disabled={editedObj}
            onClick={addDiagnosisField}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}></Grid>
    </>
  );
};

export default CancerMarker;
