// @ts-nocheck
import { Box, Button, DialogActions, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { merge } from "lodash";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import IHCForm from "./IHCForm";
import StagingPathologicalDetailsForm from "./StagingPathologicalDetailsForm";
import IHCDate from "./IHCDate";
const IHCCycle = ({
  setShowChemo,
  setChemoArray,
  updatedChemo,
  editing,
  prevSavedObj,
}) => {
  const [currentObjToEdit, setCurrentObjToEdit] = useState(null);
  const [editedObj, setEditedObj] = useState(null);

  const IHCDateRef = useRef(null);
  const IHCFormRef = useRef(null);
  const StagingPathologicalDetailsFormRef = useRef(null);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(1);

  console.log("updated ihc", currentObjToEdit);
  const handleChange = (event, value) => {
    setPage(value);
    if (
      editing &&
      updatedChemo &&
      updatedChemo.patient_ihc_observation_details &&
      updatedChemo.patient_ihc_observation_details.length > 0
    ) {
      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(
          updatedChemo.patient_ihc_observation_details[value - 1]
        );
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.patient_ihc_observation_details &&
      prevSavedObj.patient_ihc_observation_details.length > 0
    ) {
      setCurrentObjToEdit(null);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(prevSavedObj.patient_ihc_observation_details[value - 1]);
      }, 100);
    }
  };
  const resetRefValues = () => {
    IHCDateRef.current = null;
    IHCFormRef.current = null;
    StagingPathologicalDetailsFormRef.current = null;
  };
  useEffect(() => {
    if (
      editing &&
      updatedChemo &&
      updatedChemo.patient_ihc_observation_details &&
      updatedChemo.patient_ihc_observation_details.length > 0
    ) {
      setPage(updatedChemo.patient_ihc_observation_details.length);
      setTotal(updatedChemo.patient_ihc_observation_details.length);

      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(
          updatedChemo.patient_ihc_observation_details[
            updatedChemo.patient_ihc_observation_details.length - 1
          ]
        );
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.patient_ihc_observation_details &&
      prevSavedObj.patient_ihc_observation_details.length > 0
    ) {
      setPage(prevSavedObj.patient_ihc_observation_details.length);
      setTotal(prevSavedObj.patient_ihc_observation_details.length);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(
          prevSavedObj.patient_ihc_observation_details[
            prevSavedObj.patient_ihc_observation_details.length - 1
          ]
        );
      }, 100);
    }

    return () => {};
  }, [updatedChemo, editing, prevSavedObj]);
  // useEffect(() => {
  //   if (editing && updatedChemo && updatedChemo.length > 0) {
  //     setPage(updatedChemo.length);
  //     setCurrentObjToEdit(null);
  //     resetRefValues();
  //     setTimeout(() => {
  //       setCurrentObjToEdit(updatedChemo[updatedChemo.length - 1]);
  //     }, 100);
  //   }

  //   return () => {};
  // }, [savedObj]);

  return (
    <>
      {editing &&
        ((updatedChemo &&
          updatedChemo.patient_ihc_observation_details &&
          updatedChemo.patient_ihc_observation_details.length > 0) ||
          (prevSavedObj &&
            prevSavedObj.patient_ihc_observation_details &&
            prevSavedObj.patient_ihc_observation_details.length > 0)) && (
          <Stack spacing={2}>
            <Typography>Cycle: {page}</Typography>
            <Pagination count={total} page={page} onChange={handleChange} />
          </Stack>
        )}
      <IHCDate
        editedObj={editedObj}
        onStateChange={(state) => {
          IHCDateRef.current = state;
        }}
        updatedObj={currentObjToEdit}
      />
      <IHCForm
        editedObj={editedObj}
        onStateChange={(state) => {
          IHCFormRef.current = state;
        }}
        updatedObj={currentObjToEdit}
      />
      <StagingPathologicalDetailsForm
        editedObj={editedObj}
        onStateChange={(state) => {
          StagingPathologicalDetailsFormRef.current = state;
        }}
        updatedObj={currentObjToEdit}
      />
      <Box
        sx={{
          position: "sticky",
          left: 0,
          bottom: 0,
          backgroundColor: "background.paper",
          borderTop: "1px solid",
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          zIndex: 1000,
          gap: "10px",
          marginTop: "auto",
          padding: "10px",
        }}
        padding={"0px 20px"}
      >
        {" "}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1.5}
          width={"100%"}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="success"
            onClick={() => {
              let objtoPost1 = merge(
                IHCDateRef.current,
                IHCFormRef.current,
                StagingPathologicalDetailsFormRef.current
              );

              let objtoPost = {
                ...objtoPost1,
              };

              let prevArr =
                updatedChemo &&
                updatedChemo.patient_ihc_observation_details &&
                updatedChemo.patient_ihc_observation_details.length > 0
                  ? updatedChemo.patient_ihc_observation_details
                  : [];
              if (
                !updatedChemo &&
                prevSavedObj &&
                prevSavedObj.patient_ihc_observation_details &&
                prevSavedObj.patient_ihc_observation_details.length > 0
              ) {
                prevArr = prevSavedObj.patient_ihc_observation_details;
              }
              if (
                editing &&
                ((updatedChemo &&
                  updatedChemo.patient_ihc_observation_details &&
                  updatedChemo.patient_ihc_observation_details.length > 0) ||
                  (!updatedChemo &&
                    prevSavedObj &&
                    prevSavedObj.patient_ihc_observation_details &&
                    prevSavedObj.patient_ihc_observation_details.length > 0))
              ) {
                prevArr[page - 1] = objtoPost;
              } else {
                prevArr.push(objtoPost);
              }
              const obj = {
                patient_ihc_observation_details: prevArr,
              };
              resetRefValues();
              setChemoArray(obj);
              setShowChemo(false);
            }}
          >
            Submit
          </Button>
        </Box>{" "}
      </Box>
    </>
  );
};

export default IHCCycle;
