import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeAutocomplete } from "../../../FormElements";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import adminAPi from "../../../../utils/adminAPi";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import { updateObjectValue } from "../../../../utils/helper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const surgeryModalityOptions = [
  "Lumpectomy with ALND",
  "Lumpectomy with SnLNBx",
  "Mastectomy with ALND",
  "Mastectomy with SnLNBx",
  "Unplanned Limpectomy",
];
const HispathoStagingClinicalForm = ({
  onStateChange,
  editedObj,
  updatedObj,
}) => {
  const [obj, setObj] = useState({
    histopathologies: [
      {
        detail: "",
        date: null,
      },
    ],
  });

  const [modList, setModList] = useState(surgeryModalityOptions);
  const [hisValue, setHisValue] = useState([]);
  const [clinicalList, setClinicalList] = useState([]);
  const [clinicalValue, setClinicalValue] = useState([]);

  useEffect(() => {
    getModList();
    // getStagingClinical();
    return () => {};
  }, []);
  useEffect(() => {
    if (editedObj) {
      setObj(Object.assign({}, obj, editedObj));
    }
  }, [editedObj]);
  const getModList = async () => {
    const { data } = await adminAPi.get("admin/histopathology-records-all");
    setModList(data.data);
  };
  const getStagingClinical = async () => {
    const { data } = await adminAPi.get("admin/staging-clinical-records-all");
    console.log("DDDD", data);
    setClinicalList(data.data);
  };

  const handleChangeInput = (name, value) => {
    setObj({ ...obj, [name]: value });
  };
  const addDiagnosisField = () => {
    setObj({
      ...obj,
      histopathologies: [
        ...obj.histopathologies,
        {
          date: "2023-01-01",
          detail: "",
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.histopathologies];
    rows.splice(index, 1);
    setObj({ ...obj, histopathologies: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.histopathologies];
    list[index][name] = value;
    setObj({ ...obj, histopathologies: list });
  };
  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  return (
    <>
      {" "}
      <Grid item xs={8} sm={8}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid mb={1} item sm={4}>
              Hispathology
            </Grid>
          </Grid>
          {obj.histopathologies.map((data, index) => {
            const { date, detail } = data;

            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6} md={4} mb={1}>
                  <FeAutocomplete
                    options={modList && modList.map((o) => o.name)}
                    id="Detail"
                    label="Detail"
                    value={detail}
                    //  disabled={editedObj}
                    onChange={(e, v) => {
                      handleChangeDiagnosis(index, "detail", v);
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={4} mb={1}>
                  <FeDesktopDatePicker
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    name="date"
                    value={date}
                    //  disabled={editedObj}
                    onChange={(val) => {
                      handleChangeDiagnosis(
                        index,
                        "date",
                        dayjs(val).format("YYYY-MM-DD")
                      );
                    }}
                  />
                  {/* <FeAutocomplete
                    options={modList}
                    id="Modality"
                    label="Modality"
                    onChange={(event, v) => {
                      handleChangeDiagnosis(index, "detail", v.name);
                    }}
                    getOptionLabel={(option) => {
                      return editedObj ? option.detail : option.name;
                    }}
                  /> */}
                </Grid>
                <Grid item xs={2}>
                  {obj.histopathologies.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      //  disabled={editedObj}
                      onClick={() => removeDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="success"
              //  disabled={editedObj}
              onClick={addDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HispathoStagingClinicalForm;
