import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../utils/adminAPi";
import FeTextField from "../FormElements/FeTextField";

const AdminProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    getDetails();
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(`admin/auth/me`);
    setFormValue(data.data);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      setErrorMsg("");

      console.log("AAAA", formValue);
      const { data } = await adminAPi.post("admin/auth/me", formValue);
      navigate("/admin/patient-observations");
    } catch ({ response }) {
      console.log(response);
      if (response) {
        const { data } = response;
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.message) {
          setErrorMsg(data.message);
        }
      }
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <FeTextField
              name="name"
              id="name"
              label="Name"
              value={formValue.name}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="email"
              id="email"
              label="Email"
              value={formValue.email}
              onChange={handleField}
            />
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
          mt={2}
        >
          {!isEmpty(errors) && (
            <>
              {Object.keys(errors).map((o, j) => {
                return (
                  <Typography
                    key={j}
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {errors[o][0]}
                  </Typography>
                );
              })}
            </>
          )}
          {!isEmpty(errorMsg) && (
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default AdminProfile;
