import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
const CancerList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  const getList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/cancer-marker-records",
        params
      );
      setlist(data.data.data);
      setTotalPage(data.data.last_page);
      setPage(data.data.current_page);
    } catch (error) {
      console.log(error.response);
    }
  };

  const deleteItem = async (id) => {
    try {
      const { data } = await adminAPi.post(`admin/cancer-marker-records/${id}`);
      getList();
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getList();
  }, [params]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button variant="contained" onClick={() => navigate(`${pathname}/add`)}>
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Unit</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.unit}</TableCell>

                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => navigate(`${pathname}/${row.id}`)}
                      >
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteItem(row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          count={totalPage}
          page={page}
          onChange={(e, v) => {
            setPage(v);
            setParams({ ...params, page: v });
          }}
        />
      </TableContainer>
    </>
  );
};

export default CancerList;
