import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import { updateObjectValue } from "../../../../utils/helper";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const IHCDate = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    date: null,
  });

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }
    console.log("E U a", e, u);
    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <FeDesktopDatePicker
          //  disabled={editedObj}
          label="Date"
          inputFormat="DD/MM/YYYY"
          value={obj.date}
          onChange={(v) => {
            const date = dayjs(v).format("YYYY-MM-DD");
            setObj({ date });
          }}
        />
      </Grid>
    </>
  );
};

export default IHCDate;
