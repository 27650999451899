import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import Pagination from "@mui/material/Pagination";
import { FeTextField } from "../../FormElements";
import { debounce } from "lodash";

const DoctorList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getList();
    return () => {};
  }, [params]);

  const getList = async () => {
    try {
      const { data } = await adminAPi.get("admin/doctors", params);
      console.log("SSS", data);
      setlist(data.data.data);
      setTotalPage(data.data.last_page);
      setPage(data.data.current_page);
    } catch (error) {
      console.log("EE", error.response);
    }
  };

  const handleChange = (event, id) => {
    adminAPi
      .post(`admin/doctors/${id}/update-status`, {
        status: event.target.value,
      })
      .then((response) => {
        console.log(response);
        getList();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const deleteItem = async (id) => {
    try {
      const { data } = await adminAPi.post(`admin/doctors/${id}`);
      getList();
    } catch (error) {
      console.log("EE", error.response);
    }
  };
  const handleSearch = debounce((value) => {
    setParams({ ...params, search: value });
  }, 500);
  return (
    <>
      <Box display="flex" justifyContent="flex-end" m={1}>
        <FeTextField
          onChange={(e) => handleSearch(e.target.value)}
          label="Search"
        />
        <Button onClick={() => navigate(`${pathname}/add`)} variant="contained">
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>

              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell align="center">
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        value={row.status}
                        onChange={(e) => handleChange(e, row.id)}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"Inactive"}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => navigate(`${pathname}/${row.id}`)}
                      >
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteItem(row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          count={totalPage}
          page={page}
          onChange={(e, v) => {
            setPage(v);
            setParams({ ...params, page: v });
          }}
        />
      </TableContainer>
    </>
  );
};

export default DoctorList;
