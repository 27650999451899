import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

const DoctorForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    center_id: null,
    doctor_degrees: [
      {
        degree: "",
      },
    ],
  });
  const [centerList, setCenterList] = useState([]);
  const [centerValue, setCenterValue] = useState({});
  const [centerParams, setCenterParams] = useState({
    per_page: 15,
    page: 1,
  });
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);
  useEffect(() => {
    getCenter();
    return () => {};
  }, [centerParams]);

  const getCenter = async () => {
    const { data } = await adminAPi.get("admin/centers", centerParams);
    setCenterList(data.data.data);
  };

  const handleOnInputCaptureCenter = debounce((event) => {
    setCenterParams({ ...centerParams, name: event });
  }, 500);
  async function getDetails(id) {
    const { data } = await adminAPi.get(`admin/doctors/${id}`);
    setFormValue(data.data);
    setCenterValue(data.data.center);
  }

  const handleField = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue({ ...formValue, [name]: value });
  };
  const addInputField = () => {
    setFormValue({
      ...formValue,
      doctor_degrees: [
        ...formValue.doctor_degrees,
        {
          degree: "",
        },
      ],
    });
  };
  const removeInputFields = (index) => {
    const rows = [...formValue.doctor_degrees];
    rows.splice(index, 1);
    setFormValue({ ...formValue, doctor_degrees: rows });
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...formValue.doctor_degrees];
    list[index][name] = value;
    setFormValue({ ...formValue, doctor_degrees: list });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      setErrorMsg("");
      if (id) {
        console.log("AAAA", formValue);
        const { data } = await adminAPi.put(`admin/doctors/${id}`, formValue);
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        console.log("AAAA", formValue);
        const { data } = await adminAPi.post("admin/doctors", formValue);
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch ({ response }) {
      console.log(response);
      if (response) {
        const { data } = response;
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.message) {
          setErrorMsg(data.message);
        }
      }
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <FeTextField
              name="name"
              id="name"
              label="Name"
              value={formValue.name}
              onChange={handleField}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="email"
              id="email"
              label="Email"
              value={formValue.email}
              onChange={handleField}
            />
          </Grid>
          {!id && (
            <Grid item sm={4}>
              <FeTextField
                name="password"
                id="password"
                label="Password"
                value={formValue.password}
                onChange={handleField}
              />
            </Grid>
          )}
          <Grid item xs={6} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                freeSolo
                value={centerValue}
                options={centerList}
                onChange={(event, v) => {
                  const name = "center_id";
                  const value = v.id;
                  setCenterValue(v);
                  let e = { target: { name, value } };
                  handleField(e);
                }}
                onInputChange={(e, v, r) => {
                  if (r === "input") {
                    handleOnInputCaptureCenter(v);
                  } else if (r === "clear") {
                    handleOnInputCaptureCenter(v);
                  }
                }}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                label="Center Name"
              />
            </Box>
          </Grid>
        </Grid>

        <Grid mt={1} container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                Degrees
              </Grid>
            </Grid>
            {formValue.doctor_degrees.map((data, index) => {
              const { degree } = data;

              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={10} mb={1}>
                    <FeTextField
                      fullWidth
                      variant="outlined"
                      name="degree"
                      value={degree}
                      onChange={(evnt) => handleChange(index, evnt)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {formValue.doctor_degrees.length !== 1 && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => removeInputFields(index)}
                      >
                        x
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="success"
                onClick={addInputField}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
          mt={2}
        >
          {!isEmpty(errors) && (
            <>
              {Object.keys(errors).map((o, j) => {
                return (
                  <Typography
                    key={j}
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {errors[o][0]}
                  </Typography>
                );
              })}
            </>
          )}
          {!isEmpty(errorMsg) && (
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
export default DoctorForm;
