// @ts-nocheck
import { Box, Button, DialogActions, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { merge } from "lodash";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DiagGroupCalc from "./DiagGroupCalc";
import LateralityForm from "./LateralityForm";
const HormoneCycle = ({
  setShowChemo,
  setChemoArray,
  updatedChemo,
  editing,
  prevSavedObj,
}) => {
  const [currentObjToEdit, setCurrentObjToEdit] = useState(null);
  const [editedObj, setEditedObj] = useState(null);

  const DiagGroupRef = useRef(null);
  const LateralityRef = useRef(null);

  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(1);

  console.log("updated ihc", currentObjToEdit);
  const handleChange = (event, value) => {
    setPage(value);
    if (
      editing &&
      updatedChemo &&
      updatedChemo.diagnosis_groups &&
      updatedChemo.diagnosis_groups.length > 0
    ) {
      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(updatedChemo.diagnosis_groups[value - 1]);
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.diagnosis_groups &&
      prevSavedObj.diagnosis_groups.length > 0
    ) {
      setCurrentObjToEdit(null);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(prevSavedObj.diagnosis_groups[value - 1]);
      }, 100);
    }
  };
  const resetRefValues = () => {
    DiagGroupRef.current = null;
    LateralityRef.current = null;
  };
  useEffect(() => {
    if (
      editing &&
      updatedChemo &&
      updatedChemo.diagnosis_groups &&
      updatedChemo.diagnosis_groups.length > 0
    ) {
      setPage(updatedChemo.diagnosis_groups.length);
      setTotal(updatedChemo.diagnosis_groups.length);

      setCurrentObjToEdit(null);
      resetRefValues();
      setTimeout(() => {
        setCurrentObjToEdit(
          updatedChemo.diagnosis_groups[
            updatedChemo.diagnosis_groups.length - 1
          ]
        );
      }, 100);
    } else if (
      editing &&
      !updatedChemo &&
      prevSavedObj &&
      prevSavedObj.diagnosis_groups &&
      prevSavedObj.diagnosis_groups.length > 0
    ) {
      setPage(prevSavedObj.diagnosis_groups.length);
      setTotal(prevSavedObj.diagnosis_groups.length);
      setEditedObj(null);
      resetRefValues();
      setTimeout(() => {
        setEditedObj(
          prevSavedObj.diagnosis_groups[
            prevSavedObj.diagnosis_groups.length - 1
          ]
        );
      }, 100);
    }

    return () => {};
  }, [updatedChemo, editing, prevSavedObj]);
  // useEffect(() => {
  //   if (editing && updatedChemo && updatedChemo.length > 0) {
  //     setPage(updatedChemo.length);
  //     setCurrentObjToEdit(null);
  //     resetRefValues();
  //     setTimeout(() => {
  //       setCurrentObjToEdit(updatedChemo[updatedChemo.length - 1]);
  //     }, 100);
  //   }

  //   return () => {};
  // }, [savedObj]);

  console.log("current", currentObjToEdit);
  return (
    <>
      {editing &&
        ((updatedChemo &&
          updatedChemo.diagnosis_groups &&
          updatedChemo.diagnosis_groups.length > 0) ||
          (prevSavedObj &&
            prevSavedObj.diagnosis_groups &&
            prevSavedObj.diagnosis_groups.length > 0)) && (
          <Stack spacing={2}>
            <Typography>Step: {page}</Typography>
            <Pagination count={total} page={page} onChange={handleChange} />
          </Stack>
        )}
      <Grid container spacing={1}>
        <LateralityForm
          editedObj={editedObj}
          onStateChange={(state) => {
            LateralityRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
        <DiagGroupCalc
          editedObj={editedObj}
          onStateChange={(state) => {
            DiagGroupRef.current = state;
          }}
          updatedObj={currentObjToEdit}
        />
      </Grid>

      <Box
        sx={{
          position: "sticky",
          left: 0,
          bottom: 0,
          backgroundColor: "background.paper",
          borderTop: "1px solid",
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          zIndex: 1000,
          gap: "10px",
          marginTop: "auto",
          padding: "10px",
        }}
        padding={"0px 20px"}
      >
        {" "}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1.5}
          width={"100%"}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="success"
            onClick={() => {
              let objtoPost1 = merge(
                DiagGroupRef.current,
                LateralityRef.current
              );

              let objtoPost = {
                ...objtoPost1,
              };

              let prevArr =
                updatedChemo &&
                updatedChemo.diagnosis_groups &&
                updatedChemo.diagnosis_groups.length > 0
                  ? updatedChemo.diagnosis_groups
                  : [];
              if (
                !updatedChemo &&
                prevSavedObj &&
                prevSavedObj.diagnosis_groups &&
                prevSavedObj.diagnosis_groups.length > 0
              ) {
                prevArr = prevSavedObj.diagnosis_groups;
              }
              if (
                editing &&
                ((updatedChemo &&
                  updatedChemo.diagnosis_groups &&
                  updatedChemo.diagnosis_groups.length > 0) ||
                  (!updatedChemo &&
                    prevSavedObj &&
                    prevSavedObj.diagnosis_groups &&
                    prevSavedObj.diagnosis_groups.length > 0))
              ) {
                prevArr[page - 1] = objtoPost;
              } else {
                prevArr.push(objtoPost);
              }
              const obj = {
                diagnosis_groups: prevArr,
              };
              resetRefValues();
              setChemoArray(obj);
              setShowChemo(false);
            }}
          >
            Submit
          </Button>
        </Box>{" "}
      </Box>
    </>
  );
};

export default HormoneCycle;
