import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

import { FeAutocomplete, FeTextField } from "../../../FormElements";

import adminAPi from "../../../../utils/adminAPi";
import { isEmpty, parseInt } from "lodash";
import { updateObjectValue } from "../../../../utils/helper";

const ChemotherapyProtocolForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [arr, setArr] = useState([
    {
      chemotherapy_protocols: [
        {
          value: "",
          cycle_no: null,
        },
      ],
    },
  ]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    let u = null,
      e = null;

    if (updatedObj && updatedObj.chemotherapy_protocols) {
      u = true;
    }
    if (editedObj && editedObj.chemotherapy_protocols) {
      e = true;
    }

    if (u) {
      setTimeout(() => {
        const primary = updatedObj.chemotherapy_protocols?.primary;
        const followed = updatedObj.chemotherapy_protocols?.followed_by;

        let arr = [];
        let chemotherapy_protocols = [];
        if (primary.selected?.length > 0) {
          chemotherapy_protocols = primary.selected.map((o) => {
            return { value: o, cycle_no: primary.cycle_no };
          });
        }

        arr.push({ chemotherapy_protocols });

        if (followed.length > 0) {
          const transformedData = followed.map((item) => {
            const followed_by = item.selected.map((selectedItem) => {
              const cycle_no = item.cycle_no === null ? null : item.cycle_no;
              return {
                value: selectedItem,
                cycle_no: cycle_no,
              };
            });
            arr.push({ followed_by });
            return { followed_by };
          });
        }

        setArr(arr);
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        const chemArr = [...editedObj.chemotherapy_protocols];
        if (chemArr.length === 0) {
          setArr([
            {
              chemotherapy_protocols: [
                {
                  value: "",
                  cycle_no: null,
                },
              ],
            },
          ]);
          return;
        }
        const convertedArray = chemArr.map((item) => {
          const typeKey =
            item.type === "primary" ? "chemotherapy_protocols" : "followed_by";
          return {
            [typeKey]: item.chemotherapy_protocol_details.map((detail) => ({
              value: detail.value,
              cycle_no: item.cycle_no ? item.cycle_no : null,
            })),
          };
        });
        setArr(convertedArray);
      }, 100);
    } else {
      setArr([
        {
          chemotherapy_protocols: [
            {
              value: "",
              cycle_no: null,
            },
          ],
        },
      ]);
    }
  }, [updatedObj, editedObj]);

  console.log("chemotherapy_protocols arr", arr);
  // useEffect(() => {
  //   if (editedObj && editedObj.chemotherapy_protocols) {
  //     const chemArr = [...editedObj.chemotherapy_protocols];

  //     const convertedArray = chemArr.map((item) => {
  //       console.log("itemitem", item);
  //       const typeKey =
  //         item.type === "primary" ? "chemotherapy_protocols" : "followed_by";
  //       return {
  //         [typeKey]: item.chemotherapy_protocol_details.map((detail) => ({
  //           value: detail.value,
  //           cycle_no: item.cycle_no,
  //         })),
  //       };
  //     });
  //     setArr(convertedArray);

  //     // const primary = chemArr.filter((o) => o.type === "primary");
  //     // const followed = chemArr.filter((o) => o.type === "followed_by");

  //     // let chemotherapy_protocols = [];
  //     // let followed_by = [];
  //     // if (primary.length > 0) {
  //     //   primary.map((item, i) => {
  //     //     item.chemotherapy_protocol_details.map((child, k) => {
  //     //       let obj = {
  //     //         cycle_no: item.cycle_no,
  //     //         value: child.value,
  //     //       };
  //     //       chemotherapy_protocols.push(obj);
  //     //     });
  //     //   });
  //     // }
  //     // if (followed.length > 0) {
  //     //   followed.map((item, i) => {
  //     //     item.chemotherapy_protocol_details.map((child, k) => {
  //     //       let obj = {
  //     //         cycle_no: item.cycle_no,
  //     //         value: child.value,
  //     //       };
  //     //       followed_by.push(obj);
  //     //     });
  //     //   });
  //     // }

  //     // console.log("followed_by", followed_by);
  //     // if (followed_by.length > 0) {
  //     //   setArr([
  //     //    ...arr,...followed_by
  //     //   ]);
  //     // }
  //     // setObj(Object.assign({}, obj, editedObj));
  //     // chemotherapy_protocols = [], followed_by = []
  //     // if
  //   }
  // }, [editedObj]);
  // useEffect(() => {
  //   if (!editedObj && updatedObj?.chemotherapy_protocols) {
  //     const primary = updatedObj.chemotherapy_protocols?.primary;
  //     const followed = updatedObj.chemotherapy_protocols?.followed_by;

  //     let arr = [];
  //     let chemotherapy_protocols = [];
  //     if (primary.selected?.length > 0) {
  //       chemotherapy_protocols = primary.selected.map((o) => {
  //         return { value: o, cycle_no: primary.cycle_no };
  //       });
  //     }

  //     arr.push({ chemotherapy_protocols });

  //     if (followed.length > 0) {
  //       const transformedData = followed.map((item) => {
  //         const followed_by = item.selected.map((selectedItem) => {
  //           const cycle_no = item.cycle_no === null ? null : item.cycle_no;
  //           return {
  //             value: selectedItem,
  //             cycle_no: cycle_no,
  //           };
  //         });
  //         arr.push({ followed_by });
  //         return { followed_by };
  //       });
  //     }

  //     setArr(arr);
  //   }
  // }, [updatedObj]);

  console.log("ARAYAYAYAYA", arr);
  useEffect(() => {
    getList();

    return () => {};
  }, []);

  const getList = async () => {
    try {
      const { data } = await adminAPi.get(
        "admin/chemotherapy-protocol-records-all"
      );
      if (data) {
        setRows(data.data);
      }
    } catch (error) {}
  };
  const addFollowedByField = () => {
    const arrList = [...arr];
    arrList.push({
      followed_by: [
        {
          value: "",
          cycle_no: null,
        },
      ],
    });

    setArr(arrList);
  };
  const addChemoProtocolField = () => {
    const arrList = [...arr];
    arrList[0].chemotherapy_protocols = [
      {
        value: "",
        cycle_no: null,
      },
    ];

    setArr(arrList);
  };
  const addDiagnosisField = (index, childIndex) => {
    const list = [...arr];
    list[index][index === 0 ? "chemotherapy_protocols" : "followed_by"] = [
      ...list[index][index === 0 ? "chemotherapy_protocols" : "followed_by"],
      {
        value: "",
        cycle_no: null,
      },
    ];

    setArr(list);
  };
  const removeDiagnosisFields = (index, childIndex) => {
    const list = [...arr];
    const rows = [
      ...list[index][index === 0 ? "chemotherapy_protocols" : "followed_by"],
    ];
    rows.splice(childIndex, 1);
    const updatedArr = arr.map((p, i) =>
      i === index
        ? {
            [index === 0 ? "chemotherapy_protocols" : "followed_by"]: rows,
          }
        : p
    );
    setArr(updatedArr);
    // setObj({ ...obj, chemotherapy_protocols: rows });
  };
  const handleChangeDiagnosis = (index, name, value, parentIndex) => {
    const rows = [
      ...arr[parentIndex][
        parentIndex === 0 ? "chemotherapy_protocols" : "followed_by"
      ],
    ];

    // // const list = [...obj.chemotherapy_protocols];
    rows[index][name] = value;
    const updatedArr = arr.map((p, i) =>
      i === parentIndex
        ? {
            [parentIndex === 0 ? "chemotherapy_protocols" : "followed_by"]:
              rows,
          }
        : p
    );
    setArr(updatedArr);
    // setObj({ ...obj, chemotherapy_protocols: list });
  };

  useEffect(() => {
    if (
      !isEmpty(arr) &&
      arr.filter((o) => o.chemotherapy_protocols).length > 0 &&
      arr.filter((o) => o.chemotherapy_protocols)[0].chemotherapy_protocols
        .length > 0 &&
      !isEmpty(
        arr.filter((o) => o.chemotherapy_protocols)[0].chemotherapy_protocols[0]
          .value
      )
    ) {
      console.log("HHHHHHHHHH");
      const primary = arr.filter((o) => o.chemotherapy_protocols);
      const followed_by = arr.filter((o) => o.followed_by);

      let priObj = {};

      if (primary[0]?.chemotherapy_protocols?.length > 0) {
        priObj = {
          selected:
            primary.length > 0
              ? primary[0].chemotherapy_protocols.map((o) => o.value)
              : [],
          cycle_no: parseInt(
            primary.length > 0 && primary[0].chemotherapy_protocols
              ? primary[0].chemotherapy_protocols[
                  primary[0].chemotherapy_protocols.length - 1
                ].cycle_no
              : null
          ),
        };
      }
      let followed_byArr = [];
      if (followed_by.length > 0) {
        followed_by.forEach((element) => {
          if (element.followed_by?.length > 0) {
            console.log(
              "cycle_no)",
              element.followed_by.map((o) => o.cycle_no)
            );
            let followObj = {
              selected: !isEmpty(element)
                ? element.followed_by.map((o) => o.value)
                : [],
              cycle_no: parseInt(
                !isEmpty(element)
                  ? element.followed_by[element.followed_by.length - 1].cycle_no
                  : null
              ),
            };
            followed_byArr.push(followObj);
          }
        });
      }

      const mergedObj = {
        chemotherapy_protocols: {
          primary: priObj,
          followed_by: followed_byArr,
        },
      };

      onStateChange(mergedObj);
    }
  }, [arr]);
  const renderFollowedBy = (arr) => {
    if (arr && arr.length > 0) {
      let followed_byArr = [];
      if (arr.length > 0) {
        arr.forEach((element) => {
          if (element.followed_by.length > 0) {
            let followObj = {
              selected: !isEmpty(element)
                ? element.followed_by.map((o) => o.value)
                : [],
              cycle_no: parseInt(
                !isEmpty(element)
                  ? element.followed_by[element.followed_by.length - 1].cycle_no
                  : null
              ),
            };
            followed_byArr.push(followObj);
          }
        });
      }
      return (
        <>
          {followed_byArr.map((entry, index) => {
            const { selected, cycle_no } = entry;

            return (
              <span key={index}>
                {""} Followed by {selected.join(", ")}{" "}
                {cycle_no ? cycle_no : ""} Cycle
              </span>
            );
          })}
        </>
      );
    } else return <></>;
  };

  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid mb={1} item sm={4}>
                Chemotherapy Protocol{" "}
              </Grid>
            </Grid>
            {arr.filter((o) => o.chemotherapy_protocols) &&
              arr.filter((o) => o.chemotherapy_protocols).length > 0 &&
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Button
                      onClick={addChemoProtocolField}
                      variant="outlined"
                      color="success"
                      //  disabled={editedObj}
                    >
                      Add Chemoprotocol{" "}
                    </Button>
                  </Grid>
                </Grid>
              )}

            {arr.map((pData, parentIndex) => {
              const { chemotherapy_protocols = [], followed_by = [] } = pData;

              return (
                <>
                  {chemotherapy_protocols.length > 0 &&
                    chemotherapy_protocols.map((data, index) => {
                      const { value, cycle_no } = data;

                      return (
                        <Grid container spacing={2} key={index}>
                          {rows.length && rows.length > 0 && (
                            <Grid item xs={6} md={6} mb={1}>
                              <FeAutocomplete
                                options={rows.map((o) => o.name)}
                                id="LOT"
                                value={value}
                                // label="Cycle No"
                                //  disabled={editedObj}
                                onChange={(e, v) => {
                                  handleChangeDiagnosis(
                                    index,
                                    "value",
                                    v,
                                    parentIndex
                                  );
                                }}
                              />
                            </Grid>
                          )}
                          {chemotherapy_protocols.length - 1 === index && (
                            <>
                              <Grid item xs={1} md={1} mb={1}>
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="success"
                                  //  disabled={editedObj}
                                  onClick={() => addDiagnosisField(parentIndex)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              </Grid>{" "}
                              <Grid item xs={2} md={2} mb={1}>
                                <FeTextField
                                  type="number"
                                  value={cycle_no}
                                  onChange={(e, v) => {
                                    handleChangeDiagnosis(
                                      index,
                                      "cycle_no",
                                      e.target.value,
                                      parentIndex
                                    );
                                  }}
                                  label="Cycle No"
                                />{" "}
                              </Grid>
                            </>
                          )}

                          <Grid item xs={1}>
                            {/* {chemotherapy_protocols.length !== 1 && ( */}
                            <>
                              <IconButton
                                aria-label="delete"
                                size="large"
                                color="error"
                                //  disabled={editedObj}
                                onClick={() =>
                                  removeDiagnosisFields(parentIndex, index)
                                }
                              >
                                <DeleteForeverIcon fontSize="inherit" />
                              </IconButton>
                            </>
                            {/* )} */}
                          </Grid>
                        </Grid>
                      );
                    })}
                  {followed_by.length > 0 &&
                    followed_by.map((data, index) => {
                      const { value, cycle_no } = data;

                      return (
                        <Grid container spacing={2} key={index}>
                          <Grid item xs={12} md={12} mb={0}>
                            {index === 0 && (
                              <Grid container spacing={1}>
                                <Grid mb={1} item sm={4}>
                                  Followed By
                                </Grid>
                              </Grid>
                            )}{" "}
                          </Grid>

                          {rows.length && rows.length > 0 && (
                            <Grid item xs={6} md={6} mb={1}>
                              <FeAutocomplete
                                options={rows.map((o) => o.name)}
                                id="LOT"
                                value={value}
                                // label="Cycle No"
                                //  disabled={editedObj}
                                onChange={(e, v) => {
                                  handleChangeDiagnosis(
                                    index,
                                    "value",
                                    v,
                                    parentIndex
                                  );
                                }}
                              />
                            </Grid>
                          )}
                          {followed_by.length - 1 === index && (
                            <>
                              {" "}
                              <Grid item xs={1} md={1} mb={1}>
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="success"
                                  //  disabled={editedObj}
                                  onClick={() => addDiagnosisField(parentIndex)}
                                >
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              </Grid>
                              <Grid item xs={2} md={2} mb={1}>
                                <FeTextField
                                  type="number"
                                  value={cycle_no}
                                  onChange={(e, v) => {
                                    handleChangeDiagnosis(
                                      index,
                                      "cycle_no",
                                      e.target.value,
                                      parentIndex
                                    );
                                  }}
                                  label="Cycle No"
                                />{" "}
                              </Grid>
                            </>
                          )}

                          <Grid item xs={1}>
                            {/* {followed_by.length !== 1 && ( */}
                            <>
                              <IconButton
                                aria-label="delete"
                                size="large"
                                color="error"
                                //  disabled={editedObj}
                                onClick={() =>
                                  removeDiagnosisFields(parentIndex, index)
                                }
                              >
                                <DeleteForeverIcon fontSize="inherit" />
                              </IconButton>
                            </>
                            {/* )} */}
                          </Grid>
                        </Grid>
                      );
                    })}
                  {/* {arr.length - 1 === parentIndex && (
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Button
                          onClick={addFollowedByField}
                          variant="outlined"
                          color="success"
                        //  disabled={editedObj}
                        >
                          Followed By
                        </Button>
                      </Grid>
                    </Grid>
                  )} */}
                </>
              );
            })}
            {arr.filter((o) => o.chemotherapy_protocols) &&
              arr.filter((o) => o.chemotherapy_protocols).length > 0 &&
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length !== 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Button
                      onClick={addFollowedByField}
                      variant="outlined"
                      color="success"
                      //  disabled={editedObj}
                    >
                      Followed By
                    </Button>
                  </Grid>
                </Grid>
              )}

            {arr.filter((o) => o.chemotherapy_protocols).length > 0 &&
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length > 0 &&
              !isEmpty(
                arr.filter((o) => o.chemotherapy_protocols)[0]
                  .chemotherapy_protocols[0].value
              ) && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    padding: "0 15px",
                    marginTop: "1rem",
                  }}
                >
                  <h4>
                    {" "}
                    {arr
                      .filter((o) => o.chemotherapy_protocols)[0]
                      .chemotherapy_protocols.map((item, i) => item.value)
                      .join(" + ")}{" "}
                    {
                      arr.filter((o) => o.chemotherapy_protocols)[0]
                        .chemotherapy_protocols[
                        arr.filter((o) => o.chemotherapy_protocols)[0]
                          .chemotherapy_protocols.length - 1
                      ].cycle_no
                    }{" "}
                    cycle{" "}
                    <span>
                      {arr.filter((o) => o.followed_by).length > 0 &&
                        arr.filter((o) => o.followed_by)[0].followed_by.length >
                          0 &&
                        arr.filter((o) => o.followed_by)[0].followed_by[0]
                          .value !== "" && (
                          <>
                            {renderFollowedBy(arr.filter((o) => o.followed_by))}
                            {/* followed by{" "}
                            {arr
                              .filter((o) => o.followed_by)[0]
                              .followed_by.map((item, i) => item.value)
                              .join(" + ")}{" "}
                            {
                              arr.filter((o) => o.followed_by)[0].followed_by[
                                arr.filter((o) => o.followed_by)[0].followed_by
                                  .length - 1
                              ].cycle_no
                            }{" "}
                            cycle */}
                          </>
                        )}
                    </span>
                  </h4>
                </Grid>
              )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ChemotherapyProtocolForm;
