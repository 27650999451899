import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeAutocomplete } from "../../FormElements";
import FeTextField from "../../FormElements/FeTextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import FeDesktopDatePicker from "../../FormElements/FeDesktopDatePicker";
import { debounce, isEmpty } from "lodash";

const bloodGroupOptions = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
const PatientForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [disList, setDisList] = useState([]);
  const [thanaList, setThanaList] = useState([]);
  const [socioList, setSocioList] = useState([]);
  const [contraList, setContraList] = useState([]);
  const [breastList, setBreastList] = useState([]);
  const [thanaName, setThanaName] = useState(null);
  const [disName, setDisName] = useState(null);
  const [disValue, setDisValue] = useState(null);
  const [thanaValue, setThanaValue] = useState(null);
  const [formValue, setFormValue] = useState({
    name: "",
    phone: "",
    gender: "",
    date_of_birth: null,
    marital_status: null,
    blood_group: null,
    area: null,
    doctor_id: null,
  });
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [docList, setDocList] = useState([]);
  const [docValue, setDocValue] = useState({});
  const [docParams, setDocParams] = useState({
    per_page: 15,
    page: 1,
  });

  useEffect(() => {
    getDoc();
    return () => {};
  }, [docParams]);

  const getDoc = async () => {
    const { data } = await adminAPi.get("admin/doctors", docParams);
    setDocList(data.data.data);
  };
  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(`admin/patients/${id}`);
    setFormValue(data.data);
  }

  const changeFormValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormValue({ ...formValue, [name]: value });
  };

  console.log("FOFOFOF", formValue);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      setErrorMsg("");
      if (id) {
        const { data } = await adminAPi.put(`admin/patients/${id}`, formValue);
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await adminAPi.post("admin/patients", formValue);
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch ({ response }) {
      if (response) {
        const { data } = response;
        if (data && data.errors) {
          setErrors(data.errors);
        }

        if (data && data.message) {
          setErrorMsg(data.message);
        }
      }
    }
  };

  useEffect(() => {
    getSocioStatus();
    getContraList();
    getBreastExamList();

    return () => {};
  }, []);

  async function getSocioStatus(id) {
    const { data } = await adminAPi.get(
      `admin/socio-economic-status-records-all`
    );
    setSocioList(data.data);
    console.log("DKDSJDFS", data);
  }

  async function getContraList(id) {
    const { data } = await adminAPi.get(
      `admin/contraceptive-method-records-all`
    );
    setContraList(data.data);
  }

  async function getBreastExamList(id) {
    const { data } = await adminAPi.get(
      `admin/breast-examination-value-records-all`
    );
    setBreastList(data.data);
  }

  const handleChangeInput = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };
  useEffect(() => {
    getDisList(disName);

    return () => {};
  }, [disName]);
  useEffect(() => {
    getThanaList(disName, thanaName);

    return () => {};
  }, [disName, thanaName]);

  const getDisList = (name) => {
    adminAPi
      .get(`admin/districts?district=${name ? name : ""}`)
      .then((response) => {
        setDisList(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const getThanaList = (disName, thanaName) => {
    console.log("DISSS", disName, thanaName);
    if (disName)
      adminAPi
        .get(
          `admin/police-stations?district_name=${disName ? disName : ""}&name=${
            thanaName ? thanaName : ""
          }`
        )
        .then((response) => {
          console.log("response", response.data.data);
          setThanaList(response.data.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
  };
  const handleOnInputCaptureDoctor = debounce((event) => {
    setDocParams({ ...docParams, name: event });
  }, 500);
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                freeSolo
                value={docValue}
                options={docList}
                onChange={(e, v) => {
                  setDocValue(v);
                  handleChangeInput("doctor_id", v.id);
                }}
                onInputChange={(e, v, r) => {
                  if (r === "input") {
                    handleOnInputCaptureDoctor(v);
                  } else if (r === "clear") {
                    handleChangeInput("doctor_id", null);
                    handleOnInputCaptureDoctor(v);
                  }
                }}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                label="Doctor"
              />
            </Box>
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="name"
              id="group"
              label="Name"
              value={formValue.name}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item sm={4}>
            <FeTextField
              name="phone"
              id="phone"
              label="Phone"
              value={formValue.phone}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item sm={4}>
            <FeAutocomplete
              name="gender"
              id="gender"
              label="Gender"
              value={formValue.gender}
              options={["Male", "Female", "Others"]}
              onChange={(event, v) => {
                const name = "gender";
                const value = v;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              sx={{
                "& .MuiFormControl-root": {
                  width: "100%",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FeDesktopDatePicker
                  label="Date of Birth"
                  inputFormat="DD/MM/YYYY"
                  value={formValue.date_of_birth}
                  onChange={(v) => {
                    const name = "date_of_birth";
                    const value = dayjs(v).format("YYYY-MM-DD");
                    let e = { target: { name, value } };
                    changeFormValue(e);
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Marital Stattus"
              value={formValue.marital_status}
              options={["Unmarried", "Married", "Divorced", "Widowed"]}
              onChange={(event, v) => {
                const name = "marital_status";
                const value = v;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Blood Group"
              value={formValue.blood_group}
              options={bloodGroupOptions}
              onChange={(event, v) => {
                const name = "blood_group";
                const value = v;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="area"
              label="Area"
              value={formValue.area}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                freeSolo
                forcePopupIcon={true}
                value={disValue}
                key={disValue}
                // disabled={!divId}
                options={disList}
                onChange={(e, v) => {
                  if (!v) {
                    setDisValue(v);
                    setThanaValue(v);
                    setThanaName(v);
                    handleChangeInput("district", v);
                    handleChangeInput("police_station", v);
                    getDisList(null);

                    return;
                  }
                  setThanaValue(null);
                  setDisValue(v);
                  setDisName(v.district);

                  handleChangeInput("district", v.district);

                  getThanaList(v.district, null);
                }}
                onInputChange={debounce((e, v, r) => {
                  if (r === "input") {
                    setDisName(v);
                  } else if (r === "clear") {
                  }
                }, 1000)}
                getOptionLabel={(option) => {
                  return option.district || "";
                }}
                id="ConsultantPrimaryPhisician"
                label="District"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box mb={2}>
              <FeAutocomplete
                forcePopupIcon={true}
                freeSolo
                // disabled={!disId}
                value={thanaValue}
                key={thanaValue}
                options={thanaList}
                onChange={(e, v) => {
                  if (!v) {
                    setThanaValue(v);
                    handleChangeInput("police_station", v);
                    getThanaList(disName, null);

                    return;
                  }
                  setThanaValue(v);
                  handleChangeInput("police_station", v.name);
                }}
                onInputChange={debounce((e, v, r) => {
                  if (r === "input") {
                    console.log("VVVV", v);
                    setThanaName(v);
                  } else if (r === "clear") {
                  }
                }, 1000)}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                id="ConsultantPrimaryPhisician"
                label="Thana"
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Socio-Economic Status"
              value={formValue.socio_economic_status}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
              options={socioList}
              onChange={(event, v) => {
                const name = "socio_economic_status";
                const value = v.name;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="family_member"
              label="No of Family Member"
              value={formValue.family_member}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="age_of_menarche"
              label="Age of Menarche"
              value={formValue.age_of_menarche}
              onChange={changeFormValue}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="age_of_first_child"
              label="Age of first child birth"
              value={formValue.age_of_first_child}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Contraceptive Method"
              value={formValue.contraceptive_method}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
              options={contraList}
              onChange={(event, v) => {
                const name = "contraceptive_method";
                const value = v.name;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="age_of_marriage"
              label="Age of Marriage"
              value={formValue.age_of_marriage}
              onChange={changeFormValue}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="age_of_menopause"
              label="Age of Menopause"
              value={formValue.age_of_menopause}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Regular/Irregular Menstruation"
              value={formValue.regular_irregular_menstruation}
              options={["Regular", "Irregular"]}
              onChange={(event, v) => {
                const name = "regular_irregular_menstruation";
                const value = v;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Screening/Self Examination"
              value={formValue.breast_examination}
              options={["Screening", "Self Examination"]}
              onChange={(event, v) => {
                const name = "breast_examination";
                const value = v;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeAutocomplete
              label="Breast Examination Value"
              value={formValue.breast_examination_value}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
              options={breastList}
              onChange={(event, v) => {
                const name = "breast_examination_value";
                const value = v.name;
                let e = { target: { name, value } };
                changeFormValue(e);
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="exercise_per_week"
              label="Hours of Physical Exercise/week"
              value={formValue.exercise_per_week}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="dietary_habit"
              label="Dietary Habit"
              value={formValue.dietary_habit}
              onChange={changeFormValue}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="height"
              label="Height (cm)"
              value={formValue.height}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="weight"
              label="Weight (kg)"
              value={formValue.weight}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="h_o_alcoholism"
              label="H/O Alcoholism"
              value={formValue.h_o_alcoholism}
              onChange={changeFormValue}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="h_o_hrt"
              label="Any H/O HRT"
              value={formValue.h_o_hrt}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="breastfeeding_duration"
              label="Duration of Breastfeeding"
              value={formValue.breastfeeding_duration}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="rt_to_chest"
              label="Any H/O RT to chest"
              value={formValue.rt_to_chest}
              onChange={changeFormValue}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <FeTextField
              name="cancer_history"
              label="Personal/Family H/) of Cancer"
              value={formValue.cancer_history}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FeTextField
              name="known_mutation"
              label="Any Known Mutation"
              value={formValue.known_mutation}
              onChange={changeFormValue}
            />
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection={"column"}
          mt={2}
        >
          {!isEmpty(errors) && (
            <>
              {Object.keys(errors).map((o, j) => {
                return (
                  <Typography
                    key={j}
                    variant="subtitle2"
                    gutterBottom
                    sx={{ color: "red" }}
                  >
                    {errors[o][0]}
                  </Typography>
                );
              })}
            </>
          )}
          {!isEmpty(errorMsg) && (
            <Typography variant="subtitle2" gutterBottom sx={{ color: "red" }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PatientForm;
