import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { updateObjectValue } from "../../../../utils/helper";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { FeAutocomplete } from "../../../FormElements";

const lateralityOptions = ["Right", "Left", "B/Lateral"];
const LateralityForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    laterality: null,
  });

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }
    console.log("dateeeee", e, u);
    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  return (
    <>
      <Grid item xs={6} md={4}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={lateralityOptions}
            value={obj.laterality}
            id="Laterality"
            label="Laterality"
            onChange={(e, v) => {
              setObj({ laterality: v });
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default LateralityForm;
