import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  Radio,
  TableContainer,
  TableHead,
} from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import doctorAPI from "../../../../utils/doctorAPI";
import { updateObjectValue } from "../../../../utils/helper";

const GradeForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    grade: "",
  });
  useEffect(() => {
    onStateChange(obj);
  }, [obj, onStateChange]);

  useEffect(() => {
    if (editedObj) {
      setObj({ grade: parseInt(editedObj.grade) });
    }
  }, [editedObj]);
  useEffect(() => {
    if (updatedObj) {
      const u = updateObjectValue(obj, updatedObj);
      setTimeout(() => {
        setObj(u);
      }, 100);
    }
  }, [updatedObj]);
  return (
    <>
      <Grid item xs={12} md={12}>
        <Box mb={2}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            }}
          >
            <Box py={1.5} pl={2}>
              <FormLabel>Grade</FormLabel>
            </Box>
            <Table
              size="small"
              sx={{
                borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                "& tr": {
                  "&:last-child": {
                    "& td, & th": {
                      borderBottom: 0,
                    },
                  },
                },
              }}
            >
              <TableBody>
                <TableRow>
                  {[...Array(4).keys()]
                    .map((x) => ++x)
                    .map((item, i) => (
                      <TableCell align="center" key={i}>
                        <FormControlLabel
                          value={item}
                          control={
                            <Radio
                              //  disabled={editedObj}
                              value={item}
                              checked={obj.grade === item}
                              onChange={(e) => setObj({ grade: item })}
                            />
                          }
                          label={item}
                          labelPlacement="top"
                        />
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </>
  );
};

export default GradeForm;
