import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { NavLink } from "react-router-dom";
const MenuAsideDoctor = () => {
  return (
    <MenuList
      sx={{
        "& .MuiMenuItem-root": {
          p: 0,
          borderRadius: 1,
        },
        "& a": {
          display: "block",
          position: "relative",
          width: "100%",
          textDecoration: "none",
          px: 2,
          py: 1,
          color: "rgba(0, 0, 0, 0.87)",
          borderRadius: 1,
          overflow: "hidden",
          "&:before": {
            content: '""',
            display: "block",
            width: "4px",
            height: "100%",
            backgroundColor: "#205295",
            position: "absolute",
            left: 0,
            top: 0,
            opacity: 0,
          },
        },
        "& .active": {
          bgcolor: `rgba(255,255, 255, .075)`,
          color: "#fff",
          "&:before": {
            opacity: 1,
          },
        },
      }}
    >
      {/* <MenuItem>
        <NavLink to="/doctor/doctor-recognition-records">
          Doctor Recognition Records
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/doctor/patient-obs-records">
          Patient Observation Records
        </NavLink>
      </MenuItem> */}
      <MenuItem>
        <NavLink to="/doctor/patient-observations">
          Patient Observations
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to="/doctor/patients">Patients</NavLink>
      </MenuItem>
      {/* <MenuItem>
        <NavLink to="/doctor/appointments">Appointments</NavLink>
      </MenuItem> */}
    </MenuList>
  );
};
export default MenuAsideDoctor;
