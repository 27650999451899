import { Box, Grid, IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import doctorAPI from "../../../../utils/doctorAPI";
import { FeAutocomplete, FeTextField } from "../../../FormElements";
import { updateObjectValue } from "../../../../utils/helper";

const DiagGroupCalc = ({ editedObj, onStateChange, updatedObj }) => {
  const [obj, setObj] = useState({
    er: null,
    pr: null,
    her2: null,
    ki67: null,
  });
  const [list, setList] = useState([]);
  const [formObj, setFormObj] = useState({
    er: null,
    pr: null,
    her2: null,
    ki67: null,
    result: null,
  });

  useEffect(() => {
    if (!isEmpty(formObj)) onStateChange(formObj);
  }, [formObj]);

  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(formObj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(formObj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setFormObj(u);
        setObj(updateObjectValue(obj, u));
      }, 100);
      return;
    } else if (!u && e) {
      setTimeout(() => {
        setFormObj(e);
        setObj(updateObjectValue(obj, e));
      }, 100);
    }
  }, [updatedObj, editedObj]);
  // useEffect(() => {
  //   if (updatedObj) {
  //     if (updatedObj.diagnosis_groups) {
  //       setTimeout(() => {
  //         setFormObj({ diagnosis_groups: updatedObj.diagnosis_groups });
  //       }, 100);
  //     }
  //   }
  // }, [updatedObj]);
  // useEffect(() => {
  //   if (editedObj) {
  //     if (editedObj.diagnosis_groups) {
  //       setTimeout(() => {
  //         setFormObj({ diagnosis_groups: editedObj.diagnosis_groups });
  //       }, 100);
  //     }
  //   }
  // }, [editedObj]);
  useEffect(() => {
    if (
      !isEmpty(obj.er) ||
      !isEmpty(obj.pr) ||
      !isEmpty(obj.her2) ||
      !isEmpty(obj.ki67)
    ) {
      getApi(obj);
    }
  }, [obj]);
  const getApi = async (param) => {
    try {
      const { data } = await doctorAPI.post(
        "admin/diagnosis-group-calculations",
        param
      );
      if (!data) {
        setFormObj({
          ...formObj,
          result: null,
        });
        return;
      }
      setFormObj({
        ...formObj,
        result: data?.data?.result,
      });
    } catch (error) {
      setFormObj({
        ...formObj,
        result: null,
      });
    }
  };

  useEffect(() => {
    getList();

    return () => {};
  }, []);
  const getList = async () => {
    const { data } = await doctorAPI.get(
      "admin/diagnosis-group-calculation-records-all"
    );

    setList(data.data);
  };

  const handleChangeInput = (name, value) => {
    setObj({
      ...obj,
      [name]: value,
    });
    setFormObj({
      ...formObj,
      [name]: value,
    });
  };
  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            Hormone Receptor Status
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={[...new Set(list.map((o) => o.er).filter((o) => o))]}
            value={formObj?.er}
            id="Options"
            label="ER"
            onChange={(e, v) => {
              handleChangeInput("er", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={[...new Set(list.map((o) => o.pr).filter((o) => o))]}
            value={formObj?.pr}
            id="Options"
            label="PR"
            onChange={(e, v) => {
              handleChangeInput("pr", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={[...new Set(list.map((o) => o.her2).filter((o) => o))]}
            value={formObj?.her2}
            id="Options"
            label="HER2"
            onChange={(e, v) => {
              handleChangeInput("her2", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={3} md={3}>
        <Box mb={2}>
          <FeAutocomplete
            //  disabled={editedObj}
            options={[...new Set(list.map((o) => o.ki67).filter((o) => o))]}
            value={formObj?.ki67}
            id="Options"
            label="Ki67"
            onChange={(e, v) => {
              handleChangeInput("ki67", v);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <FeTextField
          label="Diagnosis Group"
          key={formObj?.result}
          value={formObj?.result}
        />
      </Grid>
    </>
  );
};

export default DiagGroupCalc;
