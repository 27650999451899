import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { FeTextField, FeAutocomplete } from "../../../FormElements";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { updateObjectValue } from "../../../../utils/helper";
import FeDesktopDatePicker from "../../../FormElements/FeDesktopDatePicker";
import dayjs from "dayjs";
import doctorAPI from "../../../../utils/doctorAPI";
import { isEmpty } from "lodash";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RadioTherapyForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    radiotherapy_schedules: [
      {
        start_date: null,
        end_date: null,
        intent: "",
        detail: [],
      },
    ],
  });

  const [groupList, setGroupList] = useState([]);
  const [radioList, setRadioList] = useState([]);

  useEffect(() => {
    getRadioList();

    return () => {};
  }, []);

  const getRadioList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/radiotherapy-schedule-records-all"
    );
    setRadioList(data.data);
  };
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      if (e.radiotherapy_schedules.length === 0) {
        setObj({
          radiotherapy_schedules: [
            {
              start_date: null,
              end_date: null,
              intent: "",
              detail: [],
            },
          ],
        });
        return;
      }
      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);

  useEffect(() => {
    getGroupList();

    return () => {};
  }, []);

  const getGroupList = async () => {
    const { data } = await doctorAPI.get(
      "doctor/radiotherapy-schedule-intent-records-all"
    );
    setGroupList(data.data);
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  const addDiagnosisField = () => {
    setObj({
      ...obj,
      radiotherapy_schedules: [
        ...obj.radiotherapy_schedules,
        {
          start_date: null,
          end_date: null,
          intent: "",
          detail: [],
        },
      ],
    });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.radiotherapy_schedules];
    rows.splice(index, 1);
    setObj({ ...obj, radiotherapy_schedules: rows });
  };
  const handleChangeDiagnosis = (index, name, value) => {
    const list = [...obj.radiotherapy_schedules];
    list[index][name] = value;
    setObj({ ...obj, radiotherapy_schedules: list });
  };

  useEffect(() => {
    if (!isEmpty(obj)) onStateChange(obj);
  }, [obj]);

  return (
    <>
      {" "}
      <Grid item xs={12} sm={12}>
        <Grid container spacing={1}>
          <Grid mb={1} item sm={4}>
            Radiotherapy Schedules
          </Grid>
        </Grid>
        {obj.radiotherapy_schedules.map((data, index) => {
          const { start_date, end_date, intent, detail } = data;

          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={3} md={3} mb={1}>
                <Box mb={2}>
                  <FeDesktopDatePicker
                    label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    name="start_date"
                    value={start_date}
                    //  disabled={editedObj}
                    onChange={(val) => {
                      handleChangeDiagnosis(
                        index,
                        "start_date",
                        dayjs(val).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} md={2} mb={1}>
                <FeDesktopDatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  name="end_date"
                  value={end_date}
                  //  disabled={editedObj}
                  onChange={(val) => {
                    handleChangeDiagnosis(
                      index,
                      "end_date",
                      dayjs(val).format("YYYY-MM-DD")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <FeAutocomplete
                  value={intent}
                  onChange={(e, v) => {
                    if (!v) {
                      handleChangeDiagnosis(index, "intent", v);

                      return;
                    }

                    handleChangeDiagnosis(index, "intent", v);
                  }}
                  label={"Intent"}
                  options={groupList.map((o) => o.value)}
                  id="Histopathology"
                />
              </Grid>

              <Grid item xs={3} md={3}>
                <FeAutocomplete
                  multiple
                  disableCloseOnSelect
                  //  disabled={editedObj}
                  value={detail.map((o) => o.value)}
                  onChange={(e, v) => {
                    handleChangeDiagnosis(
                      index,
                      "detail",
                      v.map((o) => {
                        return { value: o };
                      })
                    );
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          //  disabled={editedObj}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  options={radioList.map((o) => o.name)}
                  id="Radiotherapy Schedule"
                  label="Radiotherapy Schedule"
                />
              </Grid>
              <Grid item xs={1}>
                {obj.radiotherapy_schedules.length !== 1 && (
                  <IconButton
                    aria-label="delete"
                    size="large"
                    color="error"
                    //  disabled={editedObj}
                    onClick={() => removeDiagnosisFields(index)}
                  >
                    <DeleteForeverIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="success"
            //  disabled={editedObj}
            onClick={addDiagnosisField}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}></Grid>
    </>
  );
};

export default RadioTherapyForm;
