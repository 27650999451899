import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import { FeTextField } from "../../FormElements";

const PatientList = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [list, setlist] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [params, setParams] = useState({
    per_page: 15,
    page: 1,
  });

  const getList = async () => {
    try {
      const { data } = await adminAPi.get("admin/patients", params);
      setlist(data.data.data);
      setTotalPage(data.data.last_page);
      setPage(data.data.current_page);
    } catch (error) {
      console.log(error.response);
    }
  };

  const deleteItem = async (id) => {
    try {
      const { data } = await adminAPi.post(`admin/patients/${id}`);
      getList();
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    getList();
  }, [params]);

  const handleSearch = debounce((value) => {
    setParams({ ...params, search: value });
  }, 500);
  return (
    <>
      <Box display="flex" justifyContent="flex-end" m={1}>
        <FeTextField
          onChange={(e) => handleSearch(e.target.value)}
          label="Search"
        />
        <Button variant="contained" onClick={() => navigate(`${pathname}/add`)}>
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              {/* <TableCell>Email</TableCell> */}

              <TableCell>Date of birth</TableCell>
              <TableCell>Gender</TableCell>
              {/* <TableCell>Marital status</TableCell>
              <TableCell>Blood group</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Police station</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Referred by</TableCell>
              <TableCell>Referred by id</TableCell>
              <TableCell>Passport</TableCell>
              <TableCell>type</TableCell> */}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  {/* <TableCell>{row.email}</TableCell>
                  <TableCell>{row.nid}</TableCell> */}
                  <TableCell>{row.date_of_birth}</TableCell>
                  <TableCell>{row.gender}</TableCell>
                  {/* <TableCell>{row.marital_status}</TableCell>
                  <TableCell>{row.blood_group}</TableCell>
                  <TableCell>{row.area}</TableCell>
                  <TableCell>{row.police_station}</TableCell>
                  <TableCell>{row.photo}</TableCell>
                  <TableCell>{row.referred_by}</TableCell>
                  <TableCell>{row.referred_by_id}</TableCell>
                  <TableCell>{row.passport}</TableCell>
                  <TableCell>{row.type}</TableCell> */}
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => navigate(`${pathname}/${row.id}`)}
                      >
                        View
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => deleteItem(row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Pagination
          count={totalPage}
          page={page}
          onChange={(e, v) => {
            setPage(v);
            setParams({ ...params, page: v });
          }}
        />
      </TableContainer>
    </>
  );
};

export default PatientList;
