import { TextField } from "@mui/material";

const FeTextField = ({ defaultValue, ...params }) => {
  return (
    <TextField
      key={defaultValue}
      fullWidth
      variant="outlined"
      {...params}
      defaultValue={defaultValue}
      InputLabelProps={{ shrink: true }}
    />
  );
};
export default FeTextField;
