import * as React from "react";

import TextField from "@mui/material/TextField";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const FeDesktopDatePicker = ({ ...rest }) => {
  return (
    <DesktopDatePicker
      renderInput={(params) => <TextField {...params} />}
      {...rest}
    />
  );
};

export default FeDesktopDatePicker;
