import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";

const ChemoCyclePreview = ({ obj, cycle_no }) => {
  const renderFollowedBy = (arr) => {
    if (arr && arr.length > 0) {
      let followed_byArr = [];
      if (arr.length > 0) {
        arr.forEach((element) => {
          if (element.followed_by.length > 0) {
            let followObj = {
              selected: !isEmpty(element)
                ? element.followed_by.map((o) => o.value)
                : [],
              cycle_no: parseInt(
                !isEmpty(element)
                  ? element.followed_by[element.followed_by.length - 1].cycle_no
                  : null
              ),
            };
            followed_byArr.push(followObj);
          }
        });
      }
      return (
        <>
          {followed_byArr.map((entry, index) => {
            const { selected, cycle_no } = entry;

            return (
              <span key={index}>
                {""} Followed by {selected.join(", ")} {cycle_no} Cycle
              </span>
            );
          })}
        </>
      );
    } else return <></>;
  };

  const renderProtocols = (row) => {
    console.log("CHECMO", row);
    if (!isEmpty(row)) {
      const primary = row?.primary;
      const followed = row?.followed_by;

      let arr = [];
      let chemotherapy_protocols = [];
      if (!isEmpty(primary))
        if (primary.selected?.length > 0) {
          chemotherapy_protocols = primary.selected.map((o) => {
            return { value: o, cycle_no: primary.cycle_no };
          });
        }

      arr.push({ chemotherapy_protocols });
      if (!isEmpty(followed))
        if (followed.length > 0) {
          const transformedData = followed.map((item) => {
            const followed_by = item.selected.map((selectedItem) => {
              const cycle_no = item.cycle_no === null ? null : item.cycle_no;
              return {
                value: selectedItem,
                cycle_no: cycle_no,
              };
            });
            arr.push({ followed_by });
            return { followed_by };
          });
        }
      return (
        <h4>
          {" "}
          {arr
            .filter((o) => o.chemotherapy_protocols)[0]
            .chemotherapy_protocols.map((item, i) => item.value)
            .join(" + ")}{" "}
          {
            arr.filter((o) => o.chemotherapy_protocols)[0]
              .chemotherapy_protocols[
              arr.filter((o) => o.chemotherapy_protocols)[0]
                .chemotherapy_protocols.length - 1
            ].cycle_no
          }{" "}
          cycle{" "}
          <span>
            {arr.filter((o) => o.followed_by).length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by.length > 0 &&
              arr.filter((o) => o.followed_by)[0].followed_by[0].value !==
                "" && <>{renderFollowedBy(arr.filter((o) => o.followed_by))}</>}
          </span>
        </h4>
      );
    }
  };
  return (
    <>
      {" "}
      <Grid item xs={12} md={12}>
        <h3>Cycle No : {cycle_no}</h3>
        <h3>Chemotherapy Details :</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Starting Date</TableCell>
                <TableCell>Modality</TableCell>
                <TableCell>LOT</TableCell>
                {/* <TableCell>Cycle No.</TableCell> */}
                <TableCell>Protocol</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.chemo_starting_date &&
                    dayjs(obj.chemo_starting_date).format("DD-MM-YYYY")}
                </TableCell>

                <TableCell>
                  {obj.chemotherapy_modalities?.length > 0 &&
                    obj.chemotherapy_modalities.map((item, i) => (
                      <p
                        style={{
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          paddingBottom: "10px",
                        }}
                        key={i}
                      >
                        {" "}
                        {item.detail}
                      </p>
                    ))}
                </TableCell>
                <TableCell>{obj.line_of_treatment}</TableCell>

                <TableCell>
                  {renderProtocols(obj.chemotherapy_protocols)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3>Clinical Response Rate:</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Target lesion</TableCell>
                <TableCell>Non target lesion</TableCell>
                <TableCell>New Lasion</TableCell>
                <TableCell>Response Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.clinical_response_rate_date &&
                    dayjs(obj.clinical_response_rate_date).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>
                  {obj.clinical_response_rate_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.clinical_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.clinical_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>{obj.clinical_response_rate_result}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3>Pathological Response Rate:</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Target lesion</TableCell>
                <TableCell>Non target lesion</TableCell>
                <TableCell>New Lasion</TableCell>
                <TableCell>Response Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.pathological_response_rate_date &&
                    dayjs(obj.pathological_response_rate_date).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
                <TableCell>
                  {obj.pathological_response_rate_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.pathological_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.pathological_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>{obj.pathological_response_rate_result}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3>Radiological Response Rate:</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Target lesion</TableCell>
                <TableCell>Non target lesion</TableCell>
                <TableCell>New Lasion</TableCell>
                <TableCell>Response Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.radiological_response_rate_date &&
                    dayjs(obj.radiological_response_rate_date).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
                <TableCell>
                  {obj.radiological_response_rate_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.radiological_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>
                  {obj.radiological_response_rate_non_target_lasion}
                </TableCell>
                <TableCell>{obj.radiological_response_rate_result}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}></Grid>
      <Grid item xs={12} md={6}>
        <h3>Disease Progression Status :</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Disease Progression Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.disease_progression_status_date &&
                    dayjs(obj.disease_progression_status_date).format(
                      "DD-MM-YYYY"
                    )}
                </TableCell>
                <TableCell>{obj.disease_progression_status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <h3>Survival Status :</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Survival Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {obj.survival_status_date &&
                    dayjs(obj.survival_status_date).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>{obj.survival_status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default ChemoCyclePreview;
