import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import adminAPi from "../../../utils/adminAPi";
import FeTextField from "../../FormElements/FeTextField";

const CancerForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [formValue, setFormValue] = useState({
    name: "",
    unit: "",
  });

  useEffect(() => {
    if (id) {
      getDetails(id);
    }
  }, []);

  async function getDetails(id) {
    const { data } = await adminAPi.get(`admin/cancer-marker-records/${id}`);
    setFormValue(data.data);
  }

  const changeFormValue = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const { data } = await adminAPi.put(
          `admin/cancer-marker-records/${id}`,
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      } else {
        const { data } = await adminAPi.post(
          "admin/cancer-marker-records",
          formValue
        );
        navigate(pathname.substring(0, pathname.lastIndexOf("/")));
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <FeTextField
              name="name"
              id="name"
              label="Name"
              value={formValue.name}
              onChange={changeFormValue}
            />
          </Grid>
          <Grid item sm={6}>
            <FeTextField
              name="unit"
              id="unit"
              label="Unit"
              value={formValue.unit}
              onChange={changeFormValue}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button type="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CancerForm;
