import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { FeTextField } from "../../../FormElements";
import { updateObjectValue } from "../../../../utils/helper";
const DiagnosisForm = ({ onStateChange, editedObj, updatedObj }) => {
  const [obj, setObj] = useState({
    diagnoses: [
      {
        detail: "",
      },
    ],
    past_treatment_histories: [
      {
        detail: "",
      },
    ],
  });

  useEffect(() => {
    onStateChange(obj);
  }, [obj, onStateChange]);
  useEffect(() => {
    let u = null,
      e = null;
    if (updatedObj) {
      u = updateObjectValue(obj, updatedObj);
    }
    if (editedObj) {
      e = updateObjectValue(obj, editedObj);
    }

    if (u) {
      setTimeout(() => {
        setObj(u);
      }, 100);
      return;
    } else if (!u && e) {
      console.log("EEEEEE", e);
      if (e.diagnoses.length === 0 || e.past_treatment_histories.length === 0) {
        setObj({
          diagnoses:
            e.diagnoses.length === 0
              ? [
                  {
                    detail: "",
                  },
                ]
              : e.diagnoses,
          past_treatment_histories:
            e.past_treatment_histories.length === 0
              ? [
                  {
                    detail: "",
                  },
                ]
              : e.past_treatment_histories,
        });
        return;
      }

      setTimeout(() => {
        setObj(e);
      }, 100);
    }
  }, [updatedObj, editedObj]);
  // console.log("CONt", dataObj);
  const addDiagnosisField = () => {
    setObj({
      ...obj,
      diagnoses: [
        ...obj.diagnoses,
        {
          detail: "",
        },
      ],
    });
    // udpdateObj({
    //   ...obj,
    //   diagnoses: [
    //     ...obj.diagnoses,
    //     {
    //       detail: "",
    //     },
    //   ],
    // });
  };
  const removeDiagnosisFields = (index) => {
    const rows = [...obj.diagnoses];
    rows.splice(index, 1);
    setObj({ ...obj, diagnoses: rows });
  };
  const handleChangeDiagnosis = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...obj.diagnoses];
    list[index][name] = value;
    setObj({ ...obj, diagnoses: list });
  };
  const addPastDiagnosisField = () => {
    setObj({
      ...obj,
      past_treatment_histories: [
        ...obj.past_treatment_histories,
        {
          detail: "",
        },
      ],
    });
  };
  const removePastDiagnosisFields = (index) => {
    const rows = [...obj.past_treatment_histories];
    rows.splice(index, 1);
    setObj({ ...obj, past_treatment_histories: rows });
  };
  const handleChangePastDiagnosis = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...obj.past_treatment_histories];
    list[index][name] = value;
    setObj({ ...obj, past_treatment_histories: list });
  };

  // useEffect(() => {
  //   if (!isEmpty(obj)) onStateChange(obj);
  // }, [obj]);
  return (
    <>
      <Grid item xs={6} sm={6}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              Diagnosis in Details
            </Grid>
          </Grid>
          {obj.diagnoses.map((data, index) => {
            const { detail } = data;

            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={10} mb={1}>
                  <FeTextField
                    fullWidth
                    //  disabled={editedObj}
                    variant="outlined"
                    name="detail"
                    value={detail}
                    onChange={(evnt) => handleChangeDiagnosis(index, evnt)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {obj.diagnoses.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      //  disabled={editedObj}
                      onClick={() => removeDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              //  disabled={editedObj}
              color="success"
              onClick={addDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              Past Treatment History
            </Grid>
          </Grid>
          {obj.past_treatment_histories.map((data, index) => {
            const { detail } = data;

            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={10} mb={1}>
                  <FeTextField
                    fullWidth
                    //  disabled={editedObj}
                    variant="outlined"
                    name="detail"
                    value={detail}
                    onChange={(evnt) => handleChangePastDiagnosis(index, evnt)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {obj.past_treatment_histories.length !== 1 && (
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="error"
                      //  disabled={editedObj}
                      onClick={() => removePastDiagnosisFields(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="success"
              //  disabled={editedObj}
              onClick={addPastDiagnosisField}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DiagnosisForm;
